.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

textarea {
  width: 0px;
  height: 0px;
  resize: none;
  z-index: -1;
}

.button-upload {
  cursor: pointer;
  font: inherit;
  width: 120px;
  height: 48px;
  min-height: 48px;
  min-width: 48px;
  background-color: #3B71CA;
  color: white;
  border: 0px solid green;
  outline: none;
  border-radius: 5px;
}

.whole {
  background-color: transparent;
  text-align: left;
}


.f-l {
  float: left;
}
  
.f-r {
  float: right;
  height: 100%;
  width: 250px;
  display: flex;
  align-items: center;
}

.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner-container
{
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.spinner-container1 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  min-width: 100vh;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #3B71CA; 
  /* border-top: 10px solid #383636;*/ /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.navbarContainer {
  text-align: center;
}

.navbarContainer > div {
  width:100%;
  height: 50px;
  background-color: red;
}

.navbarLeft {
  float: left;
}

.navbarCenter {
  display: inline-block;
  margin: 0 auto;
}

.navbarRight {
  float: right;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
  backdrop-filter: blur(5px); /* apply blur filter */
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  width: 50%;
}

.modal h2 {
  margin-top: 0;
}

.modal p {
  margin-bottom: 20px;
}

.modal button {
  width: 45%;
  padding: 10px 20px;
  /* margin-right: 10px; */
  border: none;
  border-radius: 4px;
  background-color: #3B71CA;
  color: #fff;
  font-size: 16px;
  cursor: pointer;

}

.modal button:hover {
  background-color: #45a049;
}

.modal button:last-child {
  margin-right: 0;
}